import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: '创建顾客', url: '/customer-create', icon: 'person-add' },
    { title: '顾客查询', url: '/customer-search', icon: 'search' },
    { title: '系统设置', url: '/setting', icon: 'settings' },
  ];
  public labels = [];
  constructor() {}
}

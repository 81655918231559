import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'customer-search',
    pathMatch: 'full'
  },
  {
    path: 'customer-create',
    loadChildren: () => import('./customer-create/customer-create.module').then( m => m.CustomerCreatePageModule)
  },
  {
    path: 'customer-search',
    loadChildren: () => import('./customer-search/customer-search.module').then( m => m.CustomerSearchPageModule)
  },
  {
    path: 'customer/:id',
    loadChildren: () => import('./customer/customer.module').then( m => m.CustomerPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
